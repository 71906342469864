// # Header
// ## Logo
$configuration-logo-width: 356px;
$configuration-logo-width__md_down: 248px;
$configuration-logo__stuck-width: 182px;
$configuration-logo__portrait-width: 52px;
$configuration-logo__stuck__portrait-width: 52px;
// ### Logo aspect ratio variables. They have to be an integer
$configuration-logo-image-height: 104;
$configuration-logo-image-width: 356;
$configuration-logo-image__portrait-height: 150;
$configuration-logo-image__portrait-width: 109;
$configuration-logo__stuck-image__portrait-height: 130;// reduce the height to hide the bottom of a logo

// # Buttons
$configuration-button_main-border_width: 3px;
$configuration-button_main__disabled-opacity: 0.4;

// # Links
$configuration-link_2-underline_offset: 0;

// # Form
$configuration-form-input-font: 700 16px/22px $font-main;
$configuration-form-select-font: $configuration-form-input-font;

// # Product tile
$configuration-product_tile-cta_type: alt;
$configuration-product_tile-description-font_weight: normal;
$configuration-product_tile-description-letter_spacing: 0.2px;

// # Product badge
$configuration-product_badge-points-line_height: 0.8;
$configuration-product_badge-border_radius: 0;
$configuration-product_badge-font: 700 12px/24px $font-main;
$configuration-product_badge-font_size__small: 9px;

// # Promo
$configuration-promo-border_radius: 0;

// # Product quantity stepper
$configuration-product_quantity-border_width: 2px;

// # PLP & SEARCH
$configuration-filter_selected-border_radius: 3px;

// # Age gate
$configuration-age_gate-background_image: url('../images/age-gate-background.jpg');
$configuration-age_gate__mobile-background_image: url('../images/age-gate-background.jpg');
$configuration-age_gate-button_type: main;
$configuration-age_gate-button_modification: default;

// # Checkout
// ## Gift message
$color-checkout-gift_message_label: $color-accent;

// ## Confirmation page
$configuration-confirmation-account-background_image: url('../images/age-gate-background.jpg');
$configuration-confirmation-account-background_image__small: url('../images/age-gate-background.jpg');
$configuration-confirmation_create_account-button_modifications: default;

// # Error pages
$configuration-error_page__404-background_image: url('../images/age-gate-background.jpg');
$configuration-error_page__404__mobile-background_image: url('../images/age-gate-background.jpg');

// # Headings
$configuration-t_heading_1-font: 500 48px/56px $font-alt;
$configuration-t_heading_2-font: 500 36px/48px $font-alt1;
$configuration-t_heading_3-font: 500 24px/32px $font-alt1;
$configuration-t_heading_4-font: 500 28px/36px $font-alt2 !default;
$configuration-t_heading_5-font: 500 24px/40px $font-alt2 !default;
$configuration-t_heading_6-font: 500 16px/24px $font-alt2 !default;