// stylelint-disable

// # Crimson Text

@font-face {
    font-family: 'Futura Std Light';
    src: url('./fonts/FuturaStd-Light.woff2') format('woff2'),
        url('./fonts/FuturaStd-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Futura Std Medium';
    src: url('./fonts/FuturaStd-Medium.woff2') format('woff2'),
        url('./fonts/FuturaStd-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mrs Eaves OT';
    src: url('./fonts/MrsEavesOT-Italic.woff2') format('woff2'),
        url('./fonts/MrsEavesOT-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

//New Fonts
$font-main: 'Futura Std Light', sans-serif;
$font-alt: 'mrs-eaves', serif;
$font-alt1: 'Futura Std Medium', serif;
$font-alt2: 'Mrs Eaves OT', serif;
